<template>
    <div class="modal" :class="{ active: modal }">
        <div class="modal-card unlock-recommendations">
            <div class="title-container column">
                <p class="title">{{$t('recommendations.unlock.title' )}}</p>
                <p class="subtitle">{{$t('recommendations.unlock.modal.subtitle')}}</p>
            </div>
            
            <div class="modal-body">
                <div class="row center">
                    <p class="title">{{$t('recommendations.unlock.modal.cost.title')}}</p>
                    <span class="bubble">{{$t('recommendations.unlock.modal.cost.desc')}}</span>
                </div>

                
                <div class="unlock-cost">
                    <div v-if="not_enough_credits" class="title-container error">
                        <p class="title nomargin" v-html="$t('matches.detail.workProvider.modal.unlockCandidate.notEnoughCredits.title', { credits: missing_credits })"></p>
                    </div>
                </div>

                <PurchaseCredits v-if="not_enough_credits" :embedded="true" :notify_save_progress="type === 'unpublished'" :missingCredits="missing_credits" :creditUnitCost="credit_unit_cost" @cancel="close()" @purchased_credits="not_enough_credits = false" />

                <p :class="['title title-modalities', { 'nomargin-top': !not_enough_credits }]">{{$t('recommendations.unlock.modal.modalities.title')}}</p>
                <p class="modalities" v-html="$t('recommendations.unlock.modal.modalities.desc')"></p>
            </div>

            <div v-if="!not_enough_credits" class="cta-container">
                <div class="cta cta-secondary cta-slim" :class="{'loading dark': loading}" @click="unlock_advanced_intelligence()">{{$t('recommendations.unlock.title')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.words.neverMind')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import PurchaseCredits from '../../../../components/Cards/Credits/PurchaseCreditsCard.vue'

export default {
    name: 'UnlockAdvancedRecommendationsModal',

    components: { PurchaseCredits },

    props: {
        unpublished_offer_id: {
            type: String,
            default() {
                return ''
            }
        },
        
        work_provider_offer_id: {
            type: String,
            default() {
                return ''
            }
        },

        show: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default: ''
        },
    },

    watch: {
        show: function(val) {
            this.modal = val;
        },
        
        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    data() {
        return {
            not_enough_credits: false,
            missing_credits: 0,
            credit_unit_cost: 0,

            modal: false,
            loading: false,
        }
    },
    
    methods: {
        close: function(success = false) {
            const self = this;

            self.not_enough_credits = false;
            self.modal = false;
            self.loading = false;

            self.missing_credits = 0;
            self.credit_unit_cost = 0;
            
            if(success) { self.$emit('success'); }
            else { self.$emit('close'); }
        },

        unlock_advanced_intelligence: function() {
            const self = this;

            if(self.type) {
                self.loading = true;

                const data = {
                    type: self.type,
                    unpublished_offer_id: self.unpublished_offer_id,
                    work_provider_offer_id: self.work_provider_offer_id
                }

                self.$axios
                    .post('/recommendations/advanced/unlock', data)
                    .then(response => {
                        self.loading = false;
                        console.log(response);
                        
                        if(response.status == 200) {
                            self.$toasted.success(
                                self.$t('notifications.unlockedAdvancedRecommendationsSuccess'),
                                { icon: 'circle-check' }
                            );

                            self.close(true);
                        }
                    })
                    .catch((error) => {
                        self.loading = false;
                        if(error && error.response && error.response.data && error.response.data.error && error.response.data.error.key === 'notEnoughCredits') {
                            self.not_enough_credits = true;
                            self.missing_credits = error.response.data.missing_credits;
                            self.credit_unit_cost = error.response.data.credit_unit_cost;
                        }else {
                            console.log(error);
                        }
                    });
            }
        }
    },
}
</script>

