<template>
    <div v-if="valid_recommendations !== null">
        <div class="card card-recommendations card-competition">
            <div class="card-body">
                <div class="card-heading">
                    <div class="header">
                        <p class="title">{{$t('recommendations.competition.card.title')}}</p>
                        <p v-if="!sector" class="subtitle">{{$t('recommendations.competition.card.subtitles.all')}}</p>
                        <p v-if="sector" class="subtitle">{{$t('recommendations.competition.card.subtitles.sector')}}</p>
                    </div>
                    
                    <span class="beta-label" v-html="$t('language.beta')"></span>
                </div>

                <span v-if="sector" class="selected-sector" v-html="$t('recommendations.selected_sector', {sector: $t(`sectors.code.${sector}`)})"></span>
                
                <div v-if="sector" class="insights">
                    <div v-if="loading" class="insight loading"></div>
                    <div v-for="(item, index) in sector_competition" :key="index" class="insight"><span v-html="`<b>${index + 1}.</b> ${$t(`classes.${item.code_2021}`)}`" /><span :class="['percentage grey', { blur: !valid_recommendations }]">{{`${$t('recommendations.competition.card.number_of_jobs_for_class', { number: item.number_of_jobs })}`}}</span></div>
                </div>
                
                <div v-else class="insights">
                    <div v-if="loading" class="insight loading"></div>
                    <div v-for="(item, index) in all_competition" :key="index" class="insight"><span v-html="`<b>${index + 1}.</b> ${$t(`sectors.keys.${item.key}`)}`"/><span :class="['percentage grey', { blur: !valid_recommendations }]">{{`${$t('recommendations.competition.card.number_of_jobs_in_sector', { number: item.number_of_jobs })}`}}</span></div>
                </div>

                <span v-if="valid_recommendations !== null && !valid_recommendations" class="unlock">{{$t('recommendations.competition.card.unlock')}}</span>
                <div v-if="last_update" class="footnotes nopadding-left">
                    <p v-html="$t('recommendations.last_update', { time: last_update })"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompetitionCard',

    props: {
        id: {
            type: String,
            default() {
                return ''
            }
        },

        classification: {
            type: Object,
            default() {
                return null
            }
        },
        
        valid_recommendations: {
            type: Boolean,
            default() {
                return false
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        },
    },

    data() {
        return {
            last_update: '',
            all_competition: [],
            sector_competition: [],
            loading: false,
        }
    },

    watch: {
        'classification': {
            deep: true,
            handler() {
                const self = this;

                if(self.sector) { self.get_competition_for_sector(); }
                else { self.get_all_competition(); }
            }
        },

        valid_recommendations: function() {
            const self = this;
            if(self.sector) {
                self.get_competition_for_sector();
            }else {
                self.get_all_competition();
            }

            self.get_last_update();
        }
    },

    computed: {
        sector: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].sector;
            }

            return null;
        }
    },

    methods: {
        get_all_competition: function() {
            const self = this;

            self.loading = true;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    'type': self.type,
                    'id': self.id
                }
            })

            instance.get('competition/all')
                .then(response => {
                    if(response.status == 200) {
                        self.all_competition = response.data;
                        self.loading = false;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loading = false;
                });
        },

        get_competition_for_sector: function() {
            const self = this;

            self.loading = true;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    'type': self.type,
                    'id': self.id
                }
            })

            instance.get(`competition/sector/${self.sector}`)
                .then(response => {
                    if(response.status == 200) {
                        self.sector_competition = response.data;
                        self.loading = false;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loading = false;
                });
        },

        get_last_update: function() {
            const self = this;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY }
            })

            instance.get('competition/lastupdate')
                .then(response => {
                    if(response.status == 200) {
                        self.last_update = response.data.minutes;
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;
        self.get_last_update();

        if(self.sector) { self.get_competition_for_sector(); }
        else { self.get_all_competition(); }
    }
}
</script>
