<template>
    <div v-if="loaded" class="question salary column">
        <div class="label-container">
            <p>{{$t('workProviderOffer.paid.card.salaries.title')}}</p>
        </div>

        <div v-for="salary in salaries" :key="salary.experienceLevel" class="input-button-container multiple">
            <p class="group-title">{{$t(`workProviderOffer.paid.card.experienceLevels.choices.${salary.experienceLevel}`)}}</p>
            
            <div class="group">
                <the-mask v-if="salary.type === 'hourly'" :class="['input input-text', error(salary.experienceLevel), { readonly }]" :mask="['## $', '##,## $', '### $', '###,## $']" v-model="salary.amount" />
                <the-mask v-else :class="['input input-text', error(salary.experienceLevel), readonly]" :mask="['#### $', '##,### $', '###,### $']" v-model="salary.amount" />
                
                <div class="button-group collapsed">
                    <button :class="['cta cta-option', {active: salary.type === 'yearly', readonly}]" @click="update_salary_types('yearly')">{{$t('workerOffer.card.create.salary.types.yearly')}}</button>
                    <button :class="['cta cta-option', {active: salary.type === 'hourly', readonly}]" @click="update_salary_types('hourly')">{{$t('workerOffer.card.create.salary.types.hourly')}}</button>
                </div>
            </div>
        </div>

        <p v-if="salaries.length === 0" class="subtitle">{{$t('workProviderOffer.paid.card.salaries.subtitle')}}</p>
        
        <FormError v-if="!readonly" :classes="[{ nomargin: true }]" :data="data.validation.salaries" />
    </div>
</template>

<script>
import FormError from '../../components/Utils/FormError.vue'

export default {
    name: 'SalariesComponent',

    components: { FormError },

    props: {
        salaries: {
            type: Array,
            default() {
                return []
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },

        validation: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    watch: {
        salaries: function(val) {
            this.data.salaries = val;
        },

        validation: function(val) {
            this.data.validation = val;
        },

        data: {
            deep: true,
            handler() {
                const self = this;
                if(self.loaded) {
                    self.$emit('update_parent_data', self.data)
                }   
            }
        },
    },

    data() {
        return {
            data: {
                salaries: [],
                validation: {}
            },

            loaded: false
        }
    },

    methods: {
        error: function(experienceLevel) {
            try {
                if(!self.readonly) {
                    const item = self.data.salaries.find(obj => obj.experienceLevel === experienceLevel)
                    const valid = item.amount !== '0' && item.amount !== '' && self.$utils._validate_hourly_salary(item.amount);
                    
                    return { 
                        error: self.data.validation.salaries.error && !valid
                    }
                }
            }catch (e) {
                console.log(`${this.$options.name}: validation error`)
            }
        },

        update_salary_types: function(type) {
            const self = this;
            
            self.data.salaries.forEach(salary => {
                salary.type = type;
            })
        }
    },

    mounted() {
        const self = this;

        if(self.salaries && self.validation) {
            self.data = {
                salaries: self.salaries,
                validation: self.validation
            };

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    }
}
</script>

