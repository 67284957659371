<template>
    <div v-if="valid_recommendations !== null">
        <div class="card card-recommendations card-demand">
            <div class="card-body">
                <div class="card-heading">
                    <div class="header">
                        <p class="title">{{$t('recommendations.demand.card.title')}}</p>
                        <p v-if="!sector" class="subtitle">{{$t('recommendations.demand.card.subtitles.all')}}</p>
                        <p v-else class="subtitle">{{$t('recommendations.demand.card.subtitles.sector')}}</p>
                    </div>
                    
                    <span class="beta-label" v-html="$t('language.beta')"></span>
                </div>

                <div v-if="valid_recommendations">
                    <span v-if="sector" class="selected-sector" v-html="$t('recommendations.selected_sector', {sector: $t(`sectors.code.${sector}`)})"></span>
                    
                    <div v-if="sector" class="insights">
                        <div v-if="loading" class="insight loading"></div>
                        <div v-for="(item, index) in sector_demand" :key="index" class="insight"><span v-html="`<b>${index + 1}.</b> ${$t(`classes.${item.key}`)}`" /></div>
                    </div>
                    
                    <div v-if="!sector" class="insights">
                        <div v-if="loading" class="insight loading"></div>
                        <div v-for="(item, index) in all_demand" :key="index" class="insight"><span v-html="`<b>${index + 1}.</b> ${$t(`sectors.keys.${item.key}`)}`"/></div>
                    </div>

                    <span class="insufficient" v-if="sector && sector_demand.length === 0">{{$t('recommendations.demand.card.insufficient')}}</span>
                    <span class="insufficient" v-if="!sector && all_demand.length === 0">{{$t('recommendations.demand.card.insufficient')}}</span>
                </div>

                <span v-else class="unlock empty">{{$t('recommendations.demand.card.unlock')}}</span>

                <div class="footnotes nopadding-left">
                    <p v-html="$t('recommendations.real_time')"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DemandCard',

    components: {  },

    props: {
        classification: {
            type: Object,
            default() {
                return null
            }
        },

        valid_recommendations: {
            type: Boolean,
            default() {
                return false
            }
        },
    },

    data() {
        return {
            loading: false,
            all_demand: [],
            sector_demand: []
        }
    },

    watch: {
        'classification': {
            deep: true,
            handler() {
                const self = this;

                if(self.sector) { self.get_demand_for_sector(); }
                else { self.get_all_demand(); }
            }
        },

        valid_recommendations: function() {
            const self = this;
            if(self.valid_recommendations) {
                if(self.sector) {
                    self.get_demand_for_sector();
                }else {
                    self.get_all_demand();
                }
            }
        }
    },

    computed: {
        sector: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].sector;
            }

            return null;
        }
    },

    methods: {
        get_all_demand: function() {
            const self = this;
            self.loading = true;

            if (self.valid_recommendations !== null && self.valid_recommendations) {
                const instance = self.$axios.create({
                    baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                    headers: { 
                        'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    }
                })
                
                instance.get('demand/all')
                    .then(response => {
                        if(response.status == 200) {
                            self.loading = false;
                            self.all_demand = response.data;
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        self.loading = false;
                    });
            }
        },

        get_demand_for_sector: function() {
            const self = this;

            if (self.valid_recommendations !== null && self.valid_recommendations) {
                self.loading = true;

                const instance = self.$axios.create({
                    baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                    headers: { 
                        'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    }
                })
                
                instance.get(`demand/sector/${self.sector}`)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading = false;
                            self.sector_demand = response.data;
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            }
            
        },
    },

    mounted() {
        const self = this;

        if(self.sector) { self.get_demand_for_sector(); }
        else { self.get_all_demand(); }
    }
}
</script>
