<template>
    <div class="question schedule-precision column">
        <div class="label-container">
            <p>{{$t('workProviderOffer.shared.card.schedule-precision.title')}}</p>
        </div>
        
        <div class="readonly-div" v-if="readonly" v-html="data.schedule_precision.length === 0 ? 'N/A' : data.schedule_precision"></div>
        <textarea v-else id="offer-schedule-precision" :style="{ height: height }" :class="['text-area', { readonly }]" v-model="data.schedule_precision"></textarea>
    </div>
</template>

<script>
export default {
    name: 'OfferDescriptionComponent',

    props: {
        schedule_precision: {
            type: String,
            default: ''
        },

        readonly: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        data: {
            deep: true,
            handler() {
                const self = this;
                if(self.loaded) {
                    self.$emit('update', self.data);
                    self.recalculate_height();
                }
            }
        },

        schedule_precision: function(desc) {
            const self = this;
            self.data.schedule_precision = desc;
            
            self.$nextTick(() => {
                self.loaded = true;
                self.recalculate_height();
            })
        }
    },

    data() {
        return {
            data: {
                schedule_precision: ''
            },

            loaded: false,
            height: '75px',
        }
    },

    methods: {
        recalculate_height: function() {
            const self = this;
            const textarea = document.getElementById('offer-schedule-precision');

            if(!self.data.schedule_precision || self.data.schedule_precision.length === 0) { self.height = '75px'; }
            else {
                if(textarea && textarea.scrollHeight) {
                    const scroll_height = textarea.scrollHeight;

                    if(scroll_height < 75) { 
                        self.height = '75px' 
                    } else if (scroll_height > 75 && scroll_height < 500) {
                        self.height = `${scroll_height}px`; 
                    }else {
                        self.height = '500px';
                    }
                }
            }
        }
    },  

    mounted() {
        const self = this;
        self.data = { schedule_precision: self.schedule_precision ?? 'N/A' };

        self.$nextTick(() => {
            self.loaded = true;
            self.recalculate_height();
        })
    }
}
</script>

